import { useState } from "react";
import { useEffect } from "react";


export const useWindowWidth = () => {
    const [width, setWidth] = useState<number>( 0);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = () => setWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
        return undefined;
    }, []);

    return width;
};