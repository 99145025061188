import { useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';

type DeviceView = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
};

export const useDeviceDetectForDashboard = (): DeviceView => {
  const [deviceView, setDeviceView] = useState<DeviceView>(() => {
    const defaultView = {
      isMobile: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false
    };

    if (typeof window === 'undefined') {
      return {
        ...defaultView,
        isTablet: isTablet
      };
    }

    const width = window.innerWidth;
    return {
      isMobile: width <= 768,
      isTablet: width > 768 && width <= 1024,
      isLaptop: width > 1024 && width <= 1440,
      isDesktop: width > 1440
    };
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setDeviceView({
        isMobile: width <= 768,
        isTablet: width > 768 && width <= 1024,
        isLaptop: width > 1024 && width <= 1440,
        isDesktop: width > 1440
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return deviceView;
};