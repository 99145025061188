import { Suspense, lazy, useEffect, useState, useMemo } from 'react'

type Breakpoint = 'mobile' | 'tablet' | 'desktop'
type WindowSize = { width: number; height: number }

const BREAKPOINTS = {
    mobile: 480,
    tablet: 768,
    desktop: 1200,
  } as const
  

const getInitialWindowSize = (): WindowSize => ({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })
  
  const determineLayout = (width: number): Breakpoint => {
    if (width <= BREAKPOINTS.mobile) return 'mobile'
    if (width <= BREAKPOINTS.tablet) return 'tablet'
    return 'desktop'
  }
  
  export const useResponsiveLayout = (): Breakpoint => {
    const [windowSize, setWindowSize] = useState<WindowSize>(getInitialWindowSize)
  
    useEffect(() => {
      let timeoutId: NodeJS.Timeout
  
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
  
      const debouncedResize = () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(handleResize, 150)
      }
  
      window.addEventListener('resize', debouncedResize)
      handleResize()
  
      return () => {
        clearTimeout(timeoutId)
        window.removeEventListener('resize', debouncedResize)
      }
    }, [])
  
    return determineLayout(windowSize.width)
  }