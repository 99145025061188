// lib/shared/utils/screen/screen-size-checker/src/lib/screen-size-checker.ts

/**
 * Checks if the current screen size matches a specific category.
 *
 * @param {number} maxWidth - The maximum width threshold for the screen category.
 * @returns {boolean} - Returns true if the screen width is less than the specified threshold.
 *                      Returns false if the screen width is greater than or equal to the threshold,
 *                      or if the code is running in a non-browser environment.
 */
const checkScreenSize = (maxWidth: number): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < maxWidth;
  }
  return false;
};

/**
 * Checks if the current screen size is considered a phone.
 *
 * @returns {boolean} - Returns true if the screen width is less than 375 pixels, indicating a phone.
 */
export const isPhone = (): boolean => checkScreenSize(375);

/**
 * Checks if the current screen size is considered mobile.
 *
 * @returns {boolean} - Returns true if the screen width is less than 640 pixels, indicating a mobile device.
 */
export const isMobile = (): boolean => checkScreenSize(640);


/**
 * Checks if the current screen size is considered a tablet.
 *
 * @returns {boolean} - Returns true if the screen width is less than 1024 pixels, indicating a tablet.
 */
export const isTablet = (): boolean => checkScreenSize(1024);

/**
 * Checks if the current screen size is considered a laptop.
 *
 * @returns {boolean} - Returns true if the screen width is less than 1280 pixels, indicating a laptop.
 */
export const isLaptop = (): boolean => checkScreenSize(1280);

/**
 * Checks if the current screen size is considered a desktop.
 *
 * @returns {boolean} - Returns true if the screen width is less than 1920 pixels, indicating a desktop.
 */
export const isDesktop = (): boolean => checkScreenSize(1920);

/**
 * Checks if the current screen size is considered a TV.
 *
 * @returns {boolean} - Returns true if the screen width is less than 2560 pixels, indicating a TV.
 */
export const isTV = (): boolean => checkScreenSize(2560);

/**
 * Checks if the current screen size is considered 4K.
 *
 * @returns {boolean} - Returns true if the screen width is less than 3840 pixels, indicating a 4K screen.
 */
export const is4K = (): boolean => checkScreenSize(3840);
